<template>
    <v-container>
        <v-row justify="center">
            <v-col cols="12" xl="10">
                <custom-page-title page-title="menu.help.company-registration.injunction-takeover"></custom-page-title>

                <p>A cégbíróság a jogi képviselővel elektronikus úton közli az
                    eljárás során hozott végzéseket.<br>
                    A cégbíróság a jogi képviselő részére egy értesítő e-mailt küld, amelyben
                    tájékoztatja, hogy új végzése érkezett.</p>


                <p><img src="@/assets/help/images/image030.png"></p>

                <p>Az értesítő levél tartalmazza melyik cég (cégjegyzékszám),
                    melyik iratáról van szó, és található benne egy internetes hivatkozás (link),
                    amire kattintva a végzés átvehető.</p>

                <p>A végzés elektronikus átvételére 7 munkanapig van
                    lehetősége. Amennyiben 7 munkanapon belül nem veszi át a végzést, akkor a
                    cégbíróság azt papíron küldi meg.</p>

                <p>A már átvett végzések 1 hónapig érhetőek el bejelentkezés után a Cégeljárás Portál
                    <router-link to="/announcements">Küldemények és végzések</router-link>
                    menüpontjából.
                </p>

                <p><img src="@/assets/help/images/image031.png"></p>

                <p>Az értesítő e-mailben található hivatkozásra kattintva a
                    fenti képen látható weboldal jelenik meg.<br>
                    Az adatok ellenőrzése után a <b>Végzés átvétele</b> gombra kattintva
                    indul el a végzés átvételét igazoló aláírás elkészítésének folyamata. Ehhez
                    készítse elő az aláíró kártyáját és helyezze a kártyaolvasóba.</p>

                <h2>A végzés tértivevényének aláírása</h2>


                <p><img src="@/assets/help/images/image032.png"></p>

                <p>A <b>Végzés átvétele</b>
                    gomb megnyomása után a megjelenő MicroSigner ablakban kattintsunk az <b>Aláírás megkezdése</b> gombra.</p>

                <p>Az aláíró tanúsítvány kiválasztása után adjuk meg az aláírói
                    PIN kódot.</p>

                <p>A végzés átvételét követően a végzés letöltésre kerül a böngészőben.
                    A letöltött fájlra kattintva megnyithatja a végzés aktáját.
                    Az elektronikus végzések megnyitásához az e-Szignó vagy a
                    MOKKA programra van szükség, amit
                    <router-link to="/help/download">innen tölthet le</router-link>
                    .
                </p>

                <p><img src="@/assets/help/images/image033.png"></p>


                <p>Az e-Szignó programban látható a végzés aktája, amely
                    tartalmazza az ügyhöz kapcsolódó iratot. Erre duplán kattintva tekintheti meg a
                    végzés szövegét.</p>


                <p><img src="@/assets/help/images/image034.png"></p>

                <h2></h2>
                <p>A
                    <router-link to="/announcements">Cégeljárás/Küldemények és végzések</router-link>
                    menüpontban lehetősége van
                    több végzés egyidejű átvételére is.
                    A funkció használatához bejelentkezés szükséges.
                    A menüpontra kattintva kilistázásra kerül a bejelentkezett felhasználó
                    által regisztrált e-mail címről benyújtott cégbírósági beadványhoz
                    kapcsolódó valamennyi már átvett, illetve átvételre váró végzés.
                    Alapértelmezetten a szűrők nincsenek bekapcsolva,
                    így a végzéseken kívül az egyéb küldemények is láthatóak a listában.
                </p>

                <p><img src="@/assets/help/images/image035.png"></p>

                <p>A csúszka használatával lehetősége van a végzésekre és azon belül is
                    az átvételre váró végzésekre szűkíteni a listát.</p>


                <p><img src="@/assets/help/images/image036.png"></p>

                <p>A listában egyrészt lehetősége van a végzéseket egyesével is letölteni,
                    ebben az esetben a sor végén lévő letöltésre kattintva megkezdődik
                    a végzés átvételének a folyamata.</p>

                <p><img src="@/assets/help/images/image037.png"></p>

                <p>A tömeges végzésátvételhez vagy jelölje be a kockában,
                    hogy mely végzést kívánja átvenni, vagy a bal felső kocka bekattintásával
                    valamennyi átvételre váró végzés kijelölésre kerül.
                    Ezt követően a jobb felső sarokban a <b>Kijelölt végzések átvétele</b> gombra kattintva
                    a megjelölt végzések egy aláírási folyamattal vehetőek át. </p>

                <p><img src="@/assets/help/images/image038.png"></p>

                <p>Az átvételt követően a megjelölt végzések letöltésre kerülnek a felhasználó számítógépére.</p>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import {Component, Vue} from "vue-property-decorator";
import CustomPageTitle from "@/components/CustomPageTitle";

@Component({
    components: {
        CustomPageTitle
    },

})
export default class HelpInjunctionTakeover extends Vue {
}
</script>

<style scoped>
img {
    margin-top: 30px;
    margin-bottom: 30px;
}
</style>